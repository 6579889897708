export const languageHashValues= {
  "GLOBAL": ".409fedd8bd6247e58d8d",
  "U4302_TRANSACTIONSDETAIL": ".8a2b0f5f3dd1dae437a2",
  "U4400_PAYMENT": ".a95adad9c235edc75442",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".9d55727652c9ad3aff37",
  "U2117_PADAGREEMENTINVITATION": ".1dd87cb605b58fdcbe19",
  "U2000_PAD": ".861830cffd432a659d3c",
  "U4402_INVOICEPORTALPAYMENT": ".3f11d6f6433ecd7e0176",
  "U2000_BANKACCOUNTCREATION": ".4a9db3f2a30be39792e4",
  "U2011_LOGIN": ".d99e3a3922998dfea643",
  "U2012_LOGINHELP": ".94641afce0d835b9793a",
  "U2014_SECURITY": ".e876feb80ed2b1edbc7d",
  "U2086_EFTPENDINGPAYMENTS": ".97a645e20b9e8a5fd3f6",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".4516ae5a29c3d3c6c12b",
  "U2110_ACCOUNTCREATION": ".60ea14438210f6f33b70",
  "U2121_INDIVIDUALPROFILE": ".bdc0c0fe3cf9f59668da",
  "U2131_ORGANIZATIONPROFILE": ".c67d20fdd894f081a0e1",
  "U2112_ERPBINDING": ".1c2bfd8711240ddde1f9",
  "U2113_SUPPLIERINVITATION": ".351b38986740168b7beb",
  "U2118_RBBINDING": ".aaf42d6d19e44767f936",
  "U2132_AFFILIATION": ".ce8294665b1341a288d6",
  "U2133_BUSINESSLINKS": ".e7a3027094f6b5f79e4d",
  "U2181": ".535c39ab5d13b812b739",
  "U2211_SUBSCRIPTIONOFFER": ".b29c16bd16131df75442",
  "U2212_SUBSCRIPTIONFILTER": ".c46ca9d54dc5785e7fe5",
  "U2213_SUBSCRIPTION": ".9f20fc4f6f6a542b8d2e",
  "U4111_CREDITCARDADDITION": ".ae0d577ff74b53f7a066",
  "U4112_CREDITCARDDETAIL": ".1f93c714dd235105b12a",
  "U4121_BANKACCOUNTADDITION": ".c1c5b82d4a6d96665b9a",
  "U4122_BANKACCOUNTDETAIL": ".cbb2f999362273034a09",
  "U4303_ANONYMOUSTRANSACTION": ".6ddf43a55f7163f4428f",
  "U4401_PAYMENTGATEWAY": ".f3bccc2fd3b80dca285d",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".8bb102f62e4b12face06",
  "U5000_RECEIVABLE": ".d79046936f241758fcd8",
  "U5101_STATEMENTOFACCOUNT": ".ee86ac08463765130b81",
  "U5102_INVOICE": ".c809bdce9d6c9811e73d",
  "U5103_PADAGREEMENT": ".5b9b70d942b78de3bdc7",
  "U5121_SENTINVOICE": ".7647fc85119aff026a60",
  "U5124_PGRECEIVEDPAYMENT": ".5e0488e2c8f1c87de732",
  "U5120_INVOICES": ".69006f3d6d70be96abcc",
  "U5123_PADAGREEMENTCONSOLE": ".7b85e3fcb818bc0ec00d",
  "U5132_PAYMENTRECONCILIATION": ".6d0575bc8763f67092ad",
  "U5133_BANKRECONCILIATION": ".9b62f59b0742046001f6",
  "U6112_BUSINESSLINK": ".197fbd676e082a3c447f",
  "U6113_SUPPLIERRECORD": ".f6068af3013fb7e158d7",
  "U6211_PAYLOT": ".4c06b90006661daf80ad",
  "U6311_PURCHASE": ".d18ed6de634f68571461",
  "U2115_AFFILIATIONINVITATION": ".83a97bcbbe3be43bec43",
  "U6100_PAYABLE": ".f2b440d898798ca18295",
  "U6131_SENTPAYMENT": ".6a887344558079d0f5d6",
  "U6191_SENTPAYMENTREPORT": ".ac75b308bb6ade44e02c",
  "U6221_SENTPAYMENT": ".203a237a6f0a137f6c25",
  "U6422_INTERACSENTPAYMENT": ".a510b80bcd4afeec57c9",
  "U6531_TRANSACTIONPAYMENT": ".e14f960399553bcbc790",
  "U5131_RECEIVEDPAYMENT": ".6eae556afdb98a25b344",
  "U5191_RECEIVEDPAYMENTREPORT": ".6d9fc626841f0f20e7b3",
  "U2001_HOME": ".5509d3133971f3391a88",
  "U2024_REPORTLOG": ".f7c51a75790c96131a39",
  "U2111_INVOICEPORTALBINDING": ".e172116259354cd5e62d",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".b94f118572fcbb8526a1",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".7a468742efbe632de4f9",
  "U6511_TRANSACTIONPARTY": ".176a98c0ee993410d3ee",
  "U6212_PAYROLLPARTY": ".c42946ebaa6b4a83d0af",
  "U6121_EFTLOT": ".983d43cfdb805281b9f6",
  "U6412_INTERACFILE": ".f5951729132061db1642",
  "U6122_EFTAPPROVAL": ".550cb35ba8a035964c83",
  "U6512_TRANSACTIONLOT": ".84a79a04799db0d32998",
  "U6123_EFTAPPROVAL": ".e95ac2f108939a931688",
  "U6123_EFTAPPROVALMOBILE": ".a5fd233e357f97e3f2f4",
  "U2012_LOGIN": ".1f1f5142697c2bb35f33",
  "U2000_PAYMENT": ".c06fa27adf27154793ed",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".680e82087c6720aebad2",
  "U2000_PAYMENTMODEUPDATE": ".659b56bb74909b80bbb0",
  "U6114_TELUSBILLER": ".07df353e5422307187cc",
  "U4403_CREDITCARDTERMINAL": ".03b09ab08d364c51231d",
  "U4404_INTERACTERMINAL": ".34d353e72ef492a81375",
  "U2000_SAMPLECHEQUESELECTION": ".dd7abbfd9c42f6eb4468",
  "U2023_TUTORIALVIDEO": ".756da847f1bc86cea520",
  "U2134_ORGANIZATIONBILLING": ".93e8b5ab46c1ac6ce1ee",
  "U6414_BANKACCOUNTWALLET": ".17e3c6cb2ef4c00872c2",
  "U2022_INSTANTACCOUNTVERIFICATION": ".0a1f2ddc1f302a5f5650",
  "U6532_PENDINGTRANSACTION": ".f4f61ed2e76d8903a7ad",
  "U4301_TRANSACTIONSSUMMARY": ".db67d3e2402bad2312cc",
  "U5111_SENTINVOICESTRACKING": ".57121a8ed6ce3ec0e500",
  "U2026_NETPROMOTERSCORE": ".e069c6a46c464b604578",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".c19624891b0d2ccdd6b1",
  "U2000_INTRO": ".486654ee605513597871",
  "U2000_ELECTRONICSIGNATURE": ".db4f8f3fb1dbd20ee8c1",
  "U2000_VERIFYEMAIL": ".ff38d37a861ee28ba40d",
  "U2200_SUBSCRIPTION": ".18abdf7391cde86a93f0",
  "U2028_RELEASENOTE": ".b7a582b3b3b5b343d5f6",
  "U2000_VERIFYIDENTITY": ".5820f6c8f5459575e962",
  "U2000_ATTACHMENTVIEWER": ".0007ce2bdc7e902c51c0",
  "U2212_PLANLIST": ".f7d7ef3394da2c2548ca",
  "U3002_TAXBILLSEARCHRESULT": ".0ee104eb3b96402c6e2a"
}